import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/56c857c7/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Video>`}</inlineCode>{` component can render a Vimeo player or a html video player.`}</p>
    </PageDescription>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <h4 {...{
      "id": "vimeo"
    }}>{`Vimeo`}</h4>
    <Video title="Eyes" vimeoId="310583077" mdxType="Video" />
    <h4 {...{
      "id": "video"
    }}>{`Video`}</h4>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h2 {...{
      "id": "code"
    }}>{`Code`}</h2>
    <h4 {...{
      "id": "vimeo-1"
    }}>{`Vimeo`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Video/Video.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Video",
        "path": "components/Video/Video.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Video"
      }}>{`<Video title="Eyes" vimeoId="310583077" />
`}</code></pre>
    <h4 {...{
      "id": "video-1"
    }}>{`Video`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Video/Video.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Video",
        "path": "components/Video/Video.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Video"
      }}>{`<Video src="/videos/hero-video.mp4" poster="/images/poster.png">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
</Video>
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`vimeoId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To find your `}<inlineCode parentName="td">{`vimeoId`}</inlineCode>{`, go to the Vimeo page and find the video you want to put on your website. Once it is loaded, look at the URL and look for the numbers that come after the slash (/).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`src`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use the html `}<inlineCode parentName="td">{`<video>`}</inlineCode>{` player with a local `}<inlineCode parentName="td">{`.mp4`}</inlineCode>{` video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vimeo title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`poster`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provides an image to show before the video loads, only works with `}<inlineCode parentName="td">{`src`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/track"
            }}><inlineCode parentName="a">{`<track>`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`non-vimeo only`}</em>{` – Provide `}<inlineCode parentName="td">{`.vtt`}</inlineCode>{` file in your static directory to make your videos more accessible. Then add a track element with a src pointing to it Check out `}<a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/API/WebVTT_API#Tutorial_on_how_to_write_a_WebVTT_file"
            }}>{`this simple tutorial`}</a>{` for getting started with writing vtt files.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      